<template>
  <svg viewBox="0 0 16 16" class="animate-spin">
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2.66666C5.05448 2.66666 2.66666 5.05448 2.66666 8C2.66666 10.9455 5.05448 13.3333 8 13.3333C10.9455 13.3333 13.3333 10.9455 13.3333 8C13.3333 5.05448 10.9455 2.66666 8 2.66666ZM0.888885 8C0.888885 4.07264 4.07264 0.888885 8 0.888885C11.9274 0.888885 15.1111 4.07264 15.1111 8C15.1111 11.9274 11.9274 15.1111 8 15.1111C4.07264 15.1111 0.888885 11.9274 0.888885 8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.11111 1.77777C7.11111 1.28685 7.50908 0.888885 8 0.888885C11.9274 0.888885 15.1111 4.07264 15.1111 8C15.1111 8.49092 14.7131 8.88889 14.2222 8.88889C13.7313 8.88889 13.3333 8.49092 13.3333 8C13.3333 5.05448 10.9455 2.66666 8 2.66666C7.50908 2.66666 7.11111 2.26869 7.11111 1.77777Z"
      fill="currentColor"
    />
  </svg>
</template>
